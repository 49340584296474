<template>
  <div class="aps-wrap">
    <div class="aps-head">
      <ui-button @click="pushToAirtable">Push to airtable</ui-button>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'
import { paramsForServer } from 'feathers-hooks-common';

export default {
  name: 'dashboard',
  components: { UiButton },
  methods: {
    async pushToAirtable() {
      const { data } = await this.$store.dispatch('airtable-client/create', [
        {},
        paramsForServer({ sync: true })
      ]);
      console.log(data);
      alert('Done!');
    },
  },
}
</script>

<style></style>
